<template>
  <HardwareLayout v-if="true">
    <b-card
      class="projects-card"
      align="left"
    >
      <b-row>
        <b-col>
          <h4>Hardware</h4>
        </b-col>
        <b-col>
          <router-link
            :to="{ name: 'hardware-create' }"
            class="float-right"
          >
            <img :src="require('@/assets/icons/create-icon.svg')">
          </router-link>
        </b-col>
      </b-row>
      <b-row v-if="!allHardware">
        <b-col>
          <strong>¡Vaya!</strong>
          <div>Parece que no tienes hardware.</div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <b-container>
            <b-row v-for="hardware in allHardware" :key="hardware.id" class="project-row align-items-center">
              <b-col>
                <span>
                  {{ hardware.name }}
                </span>
              </b-col>
              <b-col>
                <span class="ml-auto edit-card-info-image" @click="onEdit(hardware)"></span>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-card>
  </HardwareLayout>
</template>

<script>
import HardwareLayout from '../layout/HardwareLayout.vue'
import { getAllHardwareApi } from '@/api/business/AvifyHardwareApi'

export default {
  name: 'HardwareList',
  components: { HardwareLayout },
  data () {
    return {
      allHardware: null
    }
  },
  async created () {
    this.lockScreen()
    await getAllHardwareApi().then(response => {
      this.allHardware = response
    })
    this.unlockScreen()
  },
  methods: {
    onEdit (hardware) {
      this.$router.push({
        name: 'hardware-edit',
        params: {
          id: hardware.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.project-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 16px 0;
  span {
    color: white;
  }
}
.project-row:last-child {
  border-bottom: none;
}
.future-project {
  font-weight: 700;
}
.pass-project {
  font-weight: 300;
}
.edit-card-info-image {
  background-image: url("../../../assets/icons/edit_card.svg");
  width: 32px;
  height: 32px;
  display: block;
  cursor: pointer;
}
</style>
